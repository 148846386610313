<!-- 登录界面 -->
<template>
  <div class="Login">
    <header class="header">
      <img
        src="../assets/loginBg.png"
        alt=""
      />
    </header>
    <span
      @click="goHome"
      class="backHome"
    >
      <van-icon name="home-o" />首页
    </span>
    <main class="main">
      <p>
        <van-icon
          name="user-circle-o"
          color="#a7a5a5"
          size="0.7rem"
        />
        <input
          type="number"
          placeholder="请输入手机号"
          v-model="loginObj.u_phone"
        />
      </p>
      <p v-show="pageType == 1  || pageType == 2">
        <van-icon
          name="closed-eye"
          color="#a7a5a5"
          size="0.7rem"
        />
        <input
          v-if="pageType == 1 || pageType == 2"
          type="password"
          placeholder="请输入密码"
          v-model="loginObj.u_password"
        />
        <!-- <input
          type="password"
          v-if="pageType == 3"
          placeholder="请输入新密码"
          v-model="resetPasswordObj.new_pass"
        /> -->
      </p>
      <p v-show="pageType == 2 || pageType == 3 ||pageType==4">
        <van-icon
          name="eye-o"
          color="#a7a5a5"
          size="0.7rem"
        />
        <input
          type="number"
          placeholder="请输入验证码"
          v-model="loginObj.code"
        />
        <span
          class="getCode"
          @click="getCode"
          :style="{ color: getCodeTitle == 1 ? '#f83130' : '#000' }"
        >{{ getCodeTitle == 1 ? "获取验证码" : countDown + "秒" }}</span>
      </p>

      <div class="loginTips">
        <span
          @click="changePageType(3)"
          v-show="pageType == 1"
        >验证码登录</span>
        <!-- <span
          @click="changePageType(4)"
          v-show="pageType == 1"
        >验证码登录</span> -->
        <!-- <span
          @click="changePageType(1)"
          v-show="pageType == 4"
        >密码登录</span> -->
        <!-- <span></span> -->
        <!-- <span
          @click="changePageType(1)"
          v-show="pageType == 3"
        >密码登录</span> -->
        <!-- <span
          @click="changePageType(2)"
          v-show="false"
        >没有账号？立即注册</span> -->
      </div>
    </main>
    <footer class="footer">
      <button
        @click="login"
        v-show="pageType == 1 ||pageType==4"
      >立即登录</button>
      <button
        @click="register"
        v-show="pageType == 2"
      >注册</button>
      <!-- 原重置密码 -->
      <button
        @click="login"
        v-show="pageType == 3 "
      >立即登录</button>
    </footer>
  </div>
</template>

<script>
import debounceAndThrottle from "@/tools/debounceAndThrottle";
export default {
  data() {
    return {
      pageType: 3,
      getCodeTitle: 1,
      countDown: 60,
      loginObj: {
        u_phone: "",
        code: "",
        u_nickname: "",
        u_password: "",
      },
      resetPasswordObj: {
        u_phone: "",
        code: "",
        new_pass: "",
      },
    };
  },

  components: {},

  mounted() {
    this.getToken();
  },

  methods: {
    getToken() {
      this.$axios.get("/api/Auth/getToken").then((res) => {
        console.log(res);
        if (res.code == "000") {
          this.$store.dispatch("setUserToken", res.data.token);
        }
      });
    },
    goHome() {
      this.$router.push({
        name: "cardView",
      });
    },
    changePageType(type) {
      this.pageType = type;
    },
    // 获取验证码
    getCode() {
      if (this.getCodeTitle == 2) {
        return false;
      }
      this.getCodeTitle = 2;
      this.startCountDown();
      let type = "regist";
      if (this.pageType == 2) {
        type = "regist";
      } else if (this.pageType == 3) {
        type = "login";
      } else if (this.pageType == 4) {
        type = "login";
      }

      this.$axios
        .post("/api/user/verify", {
          u_phone: this.loginObj.u_phone,
          type: type,
        })
        .then((res) => {
          console.log(res);
          if (res.code == "000") {
            this.$toast({
              type: "success",
              message: "验证码已发送",
            });
          } else {
            this.$toast({
              type: "fail",
              message: err.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            type: "fail",
            message: err.msg,
          });
        });
    },
    startCountDown() {
      let CountDownSetInterval = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--;
        } else {
          this.getCodeTitle = 1;
          this.countDown = 60;
          clearInterval(CountDownSetInterval);
        }
      }, 1000);
    },
    login() {
      debounceAndThrottle.debounce(() => {
        console.log("登录", this.pageType);
        if (this.pageType == 3) {
          this.codeLogin();
          return false;
        }

        this.$axios
          .post("/api/User/loginPhone", {
            u_phone: this.loginObj.u_phone,
            u_password: this.loginObj.u_password,
          })
          .then((res) => {
            console.log(res);
            if (res.code == "000") {
              let token = this.$store.state.token;
              window.localStorage.setItem("token", token);
              this.$router.push({
                path: "/user",
              });
            } else {
              this.$toast({
                type: "fail",
                message: res.msg,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toast({
              type: "fail",
              message: err,
            });
          });
      }, 500);
    },

    codeLogin() {
      let data = {
        u_phone: this.loginObj.u_phone,
        code: this.loginObj.code,
      };
      if (window.localStorage.getItem("partner_id")) {
        data.partner_id = window.localStorage.getItem("partner_id");
      }
      this.$axios
        .post("/api/User/logincode", data)
        .then((res) => {
          console.log(res);

          if (res.code == "000") {
            let token = this.$store.state.token;
            window.localStorage.setItem("token", token);
            this.$router.push({
              path: "/",
            });
          } else {
            this.$toast({
              type: "fail",
              message: res.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    register() {
      this.$axios
        .post("/api/User/register", {
          u_phone: this.loginObj.u_phone,
          code: this.loginObj.code,
          u_nickname: "牛牛",
          u_password: this.loginObj.u_password,
        })
        .then((res) => {
          console.log(res);
          if (res.code == "000") {
            this.$router.push({
              path: "/user",
            });
          } else {
            this.$toast({
              type: "fail",
              message: res.msg,
            });
          }
        })
        .catch((err) => {
          this.$toast({
            type: "fail",
            message: res.msg,
          });
        });
    },
    resetPassword() {
      let data = {
        u_phone: this.loginObj.u_phone,
        code: this.loginObj.code,
        new_pass: this.resetPasswordObj.new_pass,
      };
      this.$axios.post("/api/user/forgetPass", data).then((res) => {
        if (res.code == "000") {
          this.pageType = 1;
          this.loginObj.u_password = "";
          this.$toast({
            type: "sucsss",
            message: "重置成功",
          });
        } else {
          this.$toast({
            type: "fail",
            message: res.msg,
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" >
.Login {
  .backHome {
    position: fixed;
    top: 10px;
    left: 10px;
    font-size: 20px;
    color: #fff;
    display: flex;
    align-items: center;
  }
  .header {
    height: 30%;
    img {
      width: 100%;
    }
  }
  .main {
    font-size: 15px;
    // margin-top: 30px;
    p {
      padding: 0px;
      margin: 50px 10px 10px 10px;
      border-bottom: 1px solid #a7a5a5;
      padding: 5px;
      display: flex;
      align-items: center;
      position: relative;
    }
    input {
      border: none;
      outline: none;
      background: #fff;
      margin-left: 5px;
    }
    .getCode {
      color: #f83130;
      position: absolute;
      right: 4px;
    }
    .loginTips {
      float: right;
      // display: flex;
      // justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px;
      color: #063cf0;
    }
  }
  .footer {
    text-align: center;
    margin: 60px 10px 10px 10px;
    button {
      background: #f83130;
      width: 100%;
      height: 50px;
      border: none;
      border-radius: 5px;
      color: #fff;
      font-size: 20px;
      font-weight: 100;
    }
  }
}
</style>